/*
 * Asset styles in unhighlighted state.
 */

.reset-zoom-button {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  color: rgb(25, 25, 25);
  cursor: pointer;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
  margin: 8px 8px 22px 0;
  padding: 0 5px;
  text-align: center;
  display: block;
}

.reset-zoom-button.edit {
  display: none;
}

.asset {
  align-items: center;
  background-color: #ce4300;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset-inactive {
  align-items: center;
  background-color: #7e2900;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset.error {
  align-items: center;
  background-color: #ce0000;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset.error::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ce0000;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ce4300;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset-inactive::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #7e2900;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset > .icon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}

.asset-inactive > .icon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}

.asset > .clusterIcon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  color: #ffffff;
  height: 100%;
}

.asset > .icon svg {
  height: 20px;
  width: auto;
}

.asset-inactive > .icon svg {
  height: 20px;
  width: auto;
}

.asset > .clusterIcon svg {
  height: 20px;
  width: auto;
}

.asset > .popup .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.asset-inactive > .popup .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.asset > .popup {
  display: none;
}

.asset-inactive > .popup {
  display: none;
}

.asset > .popup .clusterDetails {
  display: none;
  flex-direction: column;
  flex: 1;
}

.asset > .locations-popup .delete-button {
  display: none;
}

.asset > .locations-popup .close-button {
  display: none;
}

.asset > .locations-popup .input-field {
  display: none;
}

.asset > .locations-popup .select-field {
  display: none;
}

.asset > .locations-popup .icon {
  display: none;
}

/*
 * Asset styles in highlighted state
 */
.asset.highlight {
  align-items: center;
  background-color: #ce4300;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset-inactive.highlight {
  align-items: center;
  background-color: #7e2900;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset.error.highlight {
  align-items: center;
  background-color: #ce0000;
  border-radius: 50%;
  color: #263238;
  display: block;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 35px;
  z-index: 1;
}

.asset.highlight::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ce4300;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset-inactive.highlight::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #7e2900;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset.error.highlight::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ce0000;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 90%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
}

.asset.highlight > .popup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 120px;
  z-index: 100000; /* Not a guarantee this will be on top of everything else */
  position: relative;
  top: 15px;
  left: -48px;
}

.asset-inactive.highlight > .popup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 120px;
  z-index: 100000; /* Not a guarantee this will be on top of everything else */
  position: relative;
  top: 15px;
  left: -48px;
}

.asset.highlight > .popup::after {
  border-top: 9px solid #ffffff;
  z-index: 100000; /* Not a guarantee this will be on top of everything else*/
}

.asset-inactive.highlight > .popup::after {
  border-top: 9px solid #ffffff;
  z-index: 100000; /* Not a guarantee this will be on top of everything else*/
}

.asset.highlight > .popup .details {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: black;
  margin-left: 8px;
  font-size: medium;
  z-index: 100001; /* Not a guarantee this will be on top of everything else*/
}

.asset-inactive.highlight > .popup .details {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: black;
  margin-left: 8px;
  font-size: medium;
  z-index: 100001; /* Not a guarantee this will be on top of everything else*/
}

.asset.highlight > .popup .details h6 {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.asset-inactive.highlight > .popup .details h6 {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.asset.highlight > .popup .details h5 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  font-size: 0.7em;
}

.asset-inactive.highlight > .popup .details h5 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  font-size: 0.7em;
}

.asset.highlight > .popup .input-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.asset.highlight > .popup .clusterDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  color: black;
  height: 100%;
  width: 100px;
  z-index: 100001; /* Not a guarantee this will be on top of everything else*/
}

.asset.highlight > .icon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}

.asset-inactive.highlight > .icon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}

.asset.highlight > .icon svg {
  height: 20px;
  width: auto;
}

.asset-inactive.highlight > .icon svg {
  height: 20px;
  width: auto;
}

.asset.highlight > .clusterIcon {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  color: #ffffff;
  height: 100%;
}

.asset.highlight > .popup .icon {
  height: 20px;
  width: auto;
}

.asset-inactive.highlight > .popup .icon {
  height: 20px;
  width: auto;
}

.asset.highlight > .popup .icon svg {
  height: 20px;
  width: auto;
}

.asset-inactive.highlight > .popup .icon svg {
  height: 20px;
  width: auto;
}

.asset.highlight > .locations-popup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  padding: 4px;
  width: 180px;
  z-index: 100000; /* Not a guarantee this will be on top of everything else */
  position: relative;
  top: 15px;
  left: -75px;
}

.asset.highlight > .locations-popup .icon {
  display: block;
  height: 20px;
  width: auto;
  margin-right: 8px;
}

.asset.highlight > .locations-popup .icon svg {
  height: 20px;
  width: auto;
}

.asset.highlight > .locations-popup .input-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.asset.highlight > .locations-popup .input-container .input-field:disabled {
  display: flex;
  width: 135px;
  padding: 6px;
  border: 0.5px solid black;
  border-radius: 4px;
  resize: vertical;
  font-weight: 400;
  color: black;
}

.asset.highlight > .locations-popup .input-container .select-field:disabled {
  display: flex;
  width: 135px;
  margin-top: 5px;
  border: 0.5px solid black;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.asset.edit.highlight > .locations-popup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  padding: 4px;
  width: 230px;
  z-index: 100000; /* Not a guarantee this will be on top of everything else */
  position: relative;
  top: 15px;
  left: -100px;
}

.asset.edit.highlight > .locations-popup .icon {
  display: block;
  height: 20px;
  width: auto;
  margin-right: 8px;
}

.asset.edit.highlight > .locations-popup .icon svg {
  height: 20px;
  width: auto;
}

.asset.edit.highlight > .locations-popup .input-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.asset.edit.highlight > .locations-popup .input-container .input-field:valid {
  border: 0.5px solid black;
  border-radius: 4px;
  display: flex;
  width: 135px;
  padding: 6px;
  resize: vertical;
}

.asset.edit.highlight > .locations-popup .input-container .input-field:invalid {
  outline: none;
  border: 2px solid red;
  border-radius: 4px;
  display: flex;
  width: 135px;
  padding: 6px;
  resize: vertical;
}

.asset.edit.highlight
  > .locations-popup
  .input-container
  .input-field:invalid:focus {
  outline: none;
}

.asset.edit.highlight > .locations-popup .input-container .select-field:valid {
  display: flex;
  width: 135px;
  margin-top: 5px;
}

.asset.edit.highlight
  > .locations-popup
  .input-container
  .select-field:invalid {
  display: flex;
  width: 135px;
  margin-top: 5px;
  outline: none;
  border: 2px solid red;
  border-radius: 4px;
}

.asset.edit.highlight
  > .locations-popup
  .input-container
  .select-field:invalid:focus {
  outline: none;
}

.asset.edit.highlight > .locations-popup .close-button {
  display: block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.asset.edit.highlight > .locations-popup .close-button svg {
  width: 20px;
  height: 20px;
}

.asset.edit.highlight > .locations-popup .delete-button {
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  margin-right: 8px;
}

.asset.edit.highlight > .locations-popup .delete-button svg {
  width: 20px;
  height: 20px;
}
